<template>
  <span class="render">
    <router-link :to="{ name: 'clients', query: {  }, params: {  } }" tag="a">
      <clients-icon></clients-icon>
    </router-link>
    <router-link :to="{ name: 'settings', query: {  }, params: {  } }" tag="a">
      <settings-icon></settings-icon>
    </router-link>
    <router-link :to="{ name: 'reports', query: {  }, params: {  } }" tag="a">
      <reports-icon></reports-icon>
    </router-link>
  </span>
</template>

<script>
import clientsIcon from '../../../../public/img/icons/clipboard-solid.svg'
import settingsIcon from '../../../../public/img/icons/cog-solid.svg'
import reportsIcon from '../../../../public/img/icons/file-chart-line-solid.svg'
export default {
  name: 'adminDNavigation',
  components: {
    clientsIcon,
    settingsIcon,
    reportsIcon
  }
}
</script>
